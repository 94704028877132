import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./swiperHeader.css"
import SwiperCard from "../swiperCard";

const SwiperHeader = () => {

  return (
    <>
    <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        modules={[Pagination, Navigation]}
        className="mainSwiper"
      >
        <SwiperSlide><SwiperCard header="MonoBiologics" text="Single-Use Biologics  Solutions" image="images/background/biotech-main.png" link="home"/></SwiperSlide>
      </Swiper>
    </>
  );
};

export default SwiperHeader;