import {React,useState,useEffect} from 'react';
import {Link} from 'react-router-dom'
import './navbar.css';

const Navbar = () => {

    var crossImage = "images/ui/cross.png"
    var menuImage = "images/ui/menu_icon.png"

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    function displayNavBar() {
        if(isMobile){
            var container = document.getElementById("navButtons");
            var options = document.getElementById("navOptions");

            var menuIcon = document.getElementById("menuIcon");

            if (options.style.display === "block") {
                options.style.display = "none";
                container.style.display = 'none';
                menuIcon.src = menuImage;
            } else {
                options.style.display = "block";
                container.style.display = 'block';
                menuIcon.src = crossImage;
            }
        }
    }

    window.addEventListener('resize', function(event){

        var container = document.getElementById("navButtons");
        var dropdownButton = document.getElementById("menuButton");
        var options = document.getElementById("navOptions");

        var mobileOnly = document.getElementById("mobileOnly");

        if(this.window.innerWidth >= 720){
            container.style.display = "block";
            dropdownButton.style.display = "none";
            options.style.display = "flex";
            mobileOnly.style.display = "none";
        }else{
            dropdownButton.style.display = "block";
            options.style.display = "none";
            container.style.display = 'none';
            mobileOnly.style.display = "block";
        
            document.getElementById("menuIcon").src = menuImage;
        }
      });

  return (
    <>
    <div className='container'>
        <div className='navbar'>
            <div id="menuButton" onClick={displayNavBar} className='menuButton' ><img id="menuIcon" className="menuIcon" src="images/ui/menu_icon.png"></img></div>
            <div className='logoDiv' alt="MonoBiologics Logo">
                <Link to="/home"><img src="images/logos/logo_icon_text.png"></img></Link>
            </div>
            <div id="navButtons" className='navButtons'>
                <ul className="navOptions" id="navOptions">
                    <li className="mobileOnly" id="mobileOnly" ><Link onClick={displayNavBar} to="/home">Home</Link></li>
                    <li><Link onClick={displayNavBar} to="/products">Products</Link></li>
                    <li><Link onClick={displayNavBar} to="/services">Services</Link></li>
                    <li><Link onClick={displayNavBar} to="/about">About Us</Link></li>
                    <li><Link onClick={displayNavBar} to="/quality">Quality</Link></li>
                    <li><Link onClick={displayNavBar} to="/careers">Careers</Link></li>
                    <li><Link onClick={displayNavBar} to="/contact">Contact</Link></li>
                </ul>
            </div>
        </div>
    </div>
    </>
  );
};

export default Navbar;