import React from "react";
import HeaderBlock from "../../components/headerblock";

const ContactPage = () => {

  return (
    <>
    <div className="content">
    <HeaderBlock
        subheader="Contact"
        backgroundImage="images/background/injection_molding.jpg"
        backgroundColour="#0f2b4bd4"
        headerColour="#b4d8f0"
        subHeaderColour="#b4d8f0"
        fontColour="#e1eaf0"
        textPos="right"
        html={
        <div>
          <div className="headerBlockMainHeading" >
            <p>Have any question about out products and services?</p>
            <p>Contact us via phone, email, or schedule a call to discuss your needs.</p>
            <p>Dr. Jonathan Downey<br></br>
              Co-Founder & Technical Director</p>
          </div>
          <div className='footerContentBlock' style={{textAlign:"left",width:"50%"}}>
            502 COMPLEX 5<br></br>
            RIVERSTOWN BUSINESS PARK<br></br>
            TRAMORE<br></br>
            CO. WATERFORD<br></br>
            X91 E8H7<br></br>
          </div>
          <div className='footerContentBlock' style={{textAlign:"left",width:"50%",paddingBottom:"200px"}}>
              <a href="mailto:info@monobiologics.com">
                <i className="bi bi-envelope"></i> info@monobiologics.com
              </a><br></br>
              <a href="tel:00353-51-348344">
                <i className="bi bi-telephone"></i> 00353-51-348344
              </a><br></br>
              <a href="https://twitter.com/monobiologics" target="_blank">
                <i className="bi bi-twitter"></i> @monobiologics
              </a><br></br>
              <a href="https://www.linkedin.com/company/biologics-process-manufacturing" target="_blank">
                <i className="bi bi-linkedin"></i> LinkedIn
              </a>
            </div>
        </div>} />
    </div>
    </>
  );
};

export default ContactPage;