import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import './linkbutton.css';

const LinkButton = ({text,link}) => {

  return (
    <>
    <NavHashLink 
    to={link}
    scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
    <div className='linkButton'>
        {text}
    </div>
    </NavHashLink>
    </>
  );
};

export default LinkButton;