import React from 'react';
import {Link} from 'react-router-dom'
import './textBanner.css';

const TextBanner = ({image,backgroundColour,text,header,fontColour,html}) => {

  return (
    <>
    <div className='textBannerDiv' style={{backgroundImage: `url(${image})`}}>
        <div className='textBannerDivColour' style={{backgroundColor: backgroundColour}}>
            <div className='textBannerTextDiv' style={{color:fontColour}}>
              {header===undefined?<></>:<><div className='textBannerHeader'>- {header} -</div></>}
              <div className='textBannerText'>{text}{html}</div>
            </div>
        </div>
    </div>
    </>
  );
};

export default TextBanner;