import React from "react";
import ImageDiv from "../../components/imagediv";
import HeaderBlock from "../../components/headerblock";
import TextBanner from "../../components/textbanner";
import InfoTextDiv from "../../components/textdiv";

const CareersPage = () => {
    return(
    <>
    <div className="content">
    <HeaderBlock
        header="Careers"
        backgroundImage="images/background/waterford_city.jpg"
        backgroundColour="#0f2b4bd4"
        headerColour="#b4d8f0"
        subHeaderColour="#b4d8f0"
        fontColour="#e1eaf0"
        textPos="right"
        html={<div>
          <p className="headerBlockMainHeading">
            At MonoBiologics, we value experience as much as education. What matters to us is the work you have done in the past and the projects you have undertaken. Your capability and enthusiasm matter more than how you appear on paper.
          </p>
          <p>
            Working with international BioPharma companies, we very much see MonoBiologics as being part of a customer’s own supply chain. The manufacture of healthcare products is vital, and you will play an important part in the provision of those products to the global market.
          </p>
          <p>
          Together, we are united in our mission – to partner with customers to develop single-use technologies that are not currently in their space. Driving everyone’s access to life changing medicine is the reason we exist.
          </p>
        </div>} />
    <InfoTextDiv
        primaryColor="#e1eaf0"
        header="Working in Waterford"
        htmlLeft={
          <> 
          <p>Based in Waterford, Ireland we are lucky to work in a city voted the best place to live by the Irish Times. County Waterford is the best place to live in Ireland according to a recent liveability index.</p>
          <p>The MonoBiologics manufacturing site is located a short 10-minute drive from Waterford City and is easily accessible by public transport. We are within commuting distance from across the County of Waterford as well as Carlow, Kilkenny, Tipperary, Wexford, and Cork.</p>
          <p>Check out this video for some of the many reasons why we love to live and work in Waterford.</p>
          </>
        }
        htmlRight={
          <>
          <p>
            <iframe src="https://www.youtube.com/embed/N7cO3SbeATM" 
            title="YouTube video player" frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
            className="videoDisplay">
            </iframe>
            </p>
          </>
        }
        />
    <ImageDiv
        primaryColor="#b4d8f0"
        imagePos="left"
        secondaryColor="#0a4486"
        header="Why you’ll love working at MonoBiologics"
        html={
          <>
          <p>It’s our people that make MonoBiologics a great place to work. To encourage ideas to flourish, every person who joins our team has the opportunity to progress and achieve their career goals.</p>
          <p>Along with a competitive package, we:</p>
          <ul>
            <li>Support mentoring and career development</li>
            <li>Work hard to encourage innovation within our team</li>
            <li>Embrace diversity and offer equal opportunities for all</li>
          </ul>
          </>
        }
        extraHTML={
            <>
            <p>
                At MonoBiologics, we encourage a no-nonsense culture. But what exactly does that mean? It means that your co-workers and managers trust you to do your best work, that ideas are open to debate, and that there is mutual respect.
            </p>
            <p>
                When you come to work for MonoBiologics, you not only get to work in a great environment, but you also become part of an incredibly innovative team that we think separates us from other single-use manufacturing companies.
            </p>
            <p>
                There is going to be a shift in our industry, and we intend to lead it.
            </p>
            </>
        }
        image="images/logos/logo_icon.png"
        boxShadow={false}
        radiusPercent={0} />

    <ImageDiv
        primaryColor="#e1eaf0"
        imagePos="right"
        secondaryColor="#0a4486"
        header="Join our Team of Innovators"
        html={
        <>
        <p>As we expand our manufacturing, engineering, and development operations in Waterford we are looking to fill roles in the following areas:</p>
        <ul>
            <li>Customer support, sales, and account management</li>
            <li>Engineering</li>
            <li>Finance</li>
            <li>Maintenance and facilities</li>
            <li>Operations - manufacturing operators and technicians</li>
            <li>Quality and regulatory</li>
            <li>Supply chain</li>
        </ul>
          </>
        }
        extraHTML={
            <p>Don’t have the necessary qualifications? Don’t worry. Qualifications matter less to us in most instances than you might think they should and often your work experience counts for more.</p>
        }
        image="images/innovation.jpg" />
    <TextBanner
        backgroundColour="#0f2b4bd4"
        header="Tell us More About You"
        html={
          <p>If this sounds like a great opportunity for you, send your CV to: info@monobiologics.com</p>
        }
        
        />
    </div>
    </>)
}

export default CareersPage;