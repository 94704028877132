import React from "react";
import ImageDiv from "../../components/imagediv";
import HeaderBlock from "../../components/headerblock";
import TextBanner from "../../components/textbanner";
import InfoTextDiv from "../../components/textdiv";

const ServicesPage = () => {

  return (
    <>
      <div className="content">
        <HeaderBlock header="Services"
          subheader="Creating solutions for your needs."
          backgroundImage="images/background/processing_1.jpg"
          backgroundColour="#0f2b4bd4"
          headerColour="#b4d8f0"
          subHeaderColour="#b4d8f0"
          fontColour="#e1eaf0"
          textPos="right"
          html={<div>
            <p>You know your process, and we know what technologies can assist in improving process efficiencies, quality, and yields. While our standard flow path components are suitable for most biologics manufacturing requirements, we can make bespoke amendments to suit your needs.</p>
            <p>Using our experience working with many large biologics manufacturing sites both in the US and Europe we also support the implementation and validation of custom process technologies.</p>
          </div>} />
        <ImageDiv
          primaryColor="#e1eaf0"
          secondaryColor="#0a4486"
          header="Design Consultancy"
          id="designconsultancy"
          imagePos="right"
          html={
            <>
              <p>Do you need a custom single-use configuration? Our design team will work with you to develop a custom single-use configurations and outline how we support other customers with more standard solutions.</p>
              <p>We have a team of design engineers who have a wide variety of skills to support your custom requirements. This team comprises of skillsets in:</p>
              <ul>
                <li>Mechanical Engineering</li>
                <li>Manufacturing and Process Engineering</li>
                <li>Electrical and electronic engineering</li>
                <li>Design Engineering</li>
                <li>Chemical and Bioprocessing</li>
              </ul>
            </>
          }
          image="images/production_4.jpg" />
      </div>
      <ImageDiv
        primaryColor="#b4d8f0"
        imagePos="left"
        secondaryColor="#0a4486"
        header="Supporting your transition to single-use products"
        id="transitionsdd"
        html={
          <>
          <p>As the single-use transition happens, we can support:</p>
          <ul>
            <li>Valving and all the stainless-steel elements that still need to be part of your process</li>
            <li>Deploying sensors for both existing single-use and hybrid processes</li>
          </ul>
          </>
        }
        image="images/bioclosure_2.jpg" />

        <InfoTextDiv
        header = "Cell and Gene therapy solutions"
        id="cellgenetherapy"
        primaryColor="#e1eaf0"
        htmlLeft={
          <> 
          <p>Accuracy is vitally important when processing small quantities.</p>
          <p>Together with our customers, we are developing the capabilities to support the implementation of smaller batch Cell and Gene therapy solutions.</p>
          <p>Cell and Gene therapies are one of the faster-moving new technologies in our industry. We are in a unique position to support you from R&D to production ramp-up. Our technologies allow for the development and manufacture of components and flow paths that can process small, highly valuable quantities of biologics products.</p>
          </>
        }
        htmlRight={
          <>
          <p>As the single-use industry moves more towards next-generation solutions for cell and gene therapies we are responsive to changing technological demands through:
          <ul>
            <li>Providing technologies that maintain smaller volume manufacturing consistency</li>
            <li>Ensuring that our technologies are viable for small volume manufacturing and processing</li>
            <li>Assisting with adopting single-use processing technologies to reduce process changeover times from batch to batch</li>
          </ul>
        </p>
        <p>Getting the processing of cells right on the first go is important. Through our process control elements, you can significantly reduce dead-leg and hold up volumes. With our technologies, you can have confidence that your upstream and downstream proccing will be completely robust.</p>
        
          </>
        }
        />
    </>
  );
};

export default ServicesPage;