import React from 'react'
import './footer.css';

import {Link} from "react-router-dom";

const Footer = () => {

  return (
    <>
    <div className='footer'>
      <div className='footerContent'>
      <div className='footerContentBlock'>
          <img src='images/logos/logo_icon_text.png'></img>
          <div className='footerHeading'>Single-use biologics solutions</div>
          <div className='footerHeading'>Contact Us</div>
          <div>
              <a href="mailto:info@monobiologics.com">
                <i className="bi bi-envelope"></i> info@monobiologics.com
              </a><br></br>
              <a href="tel:00353-51-348344">
                <i className="bi bi-telephone"></i> 00353-51-348344
              </a><br></br>
              <a href="https://twitter.com/monobiologics" target="_blank">
                <i className="bi bi-twitter"></i> @monobiologics
              </a><br></br>
              <a href="https://www.linkedin.com/company/biologics-process-manufacturing" target="_blank">
                <i className="bi bi-linkedin"></i> LinkedIn
              </a>
            </div>
          </div>
          
          <div className='footerContentBlock'>
            
            <div className='footerHeading'><br></br>Quick Access</div>
              <div className='footerSplitOptions'>
                <ul>
                  <li><Link to="/home">Home</Link></li>
                  <li><Link to="/products">Products</Link></li>
                  <li><Link to="/services">Services</Link></li>
                </ul>
              </div>
              <div className='footerSplitOptions'>    
              <ul>          
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/quality">Quality</Link></li>
                <li><Link to="/careers">Careers</Link></li>
                <li><Link to="/contact">Contact</Link></li>
              </ul>
              </div>
          </div>
          <div className='footerBottom'>
            <div className='footerMiddleContent'>©MonoBiologics</div>
          </div>
      </div>
    </div>
    </>
  );
};

export default Footer;