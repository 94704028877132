import React from 'react';
import './imagediv.css';
import { Link } from "react-router-dom";

const ImageDiv = ({image, html, header, primaryColor, secondaryColor, button, buttonText, buttonLink, imagePos, extraHTML,radiusPercent,boxShadow,id}) => {

  if(radiusPercent===undefined){
    radiusPercent=0
  }

  var shadow;

  if(boxShadow === true){
    shadow =  "1px 1px 12px black, 0 0 16px darkgray";
  }else{
    shadow = "0px 0px #ffffff00, 0 0 0px #ffffff00;"
  }


  return (
    imagePos === "left" ?
      <div style={{ backgroundColor: primaryColor }} >
        
        <div className="flex-container" style={{ backgroundColor: primaryColor }}>

          <div className="flex-child left">
          <div className='imageDiv' style={{borderColor: secondaryColor, borderRadius:radiusPercent+"%", boxShadow:shadow,backgroundImage: `url(${image})`}}>
          </div>
          </div>

          <div className="flex-child right">
            <h2 className="info-h2" style={{ color: secondaryColor }}>{header}</h2>
            <p className="info-p" style={{ color: secondaryColor }}>
              {html}
              <div className="info-spacer"></div>
              {button ? <Link to={buttonLink}><button className="info-button" style={{ color: primaryColor, backgroundColor: secondaryColor }} >{buttonText}</button></Link> : <></>}
            </p>
          </div>
        </div>
        <div className='imageDivExtraHHTML' style={{ color: secondaryColor }} id={id}>
          {extraHTML}
        </div>
      </div>


      :

      <div style={{ backgroundColor: primaryColor }} id={id}>
        <div className="flex-container" style={{ backgroundColor: primaryColor }}>
          <div className="flex-child left">
            <h2 className="info-h2" style={{ color: secondaryColor }}>{header}</h2>
            <p className="info-p" style={{ color: secondaryColor }}>
              {html}
              <div className="info-spacer"></div>
              {button ? <Link to={buttonLink}><button className="info-button" style={{ color: primaryColor, backgroundColor: secondaryColor }} >{buttonText}</button></Link> : <></>}
            </p>
          </div>

          <div className="flex-child right">

          <div className='imageDiv' style={{borderColor: secondaryColor, borderRadius:radiusPercent+"%", boxShadow:shadow,backgroundImage: `url(${image})`}}>
          </div>
          </div>
        </div>
        <div className='imageDivExtraHHTML' style={{ color: secondaryColor }} id={id}>
          {extraHTML}
        </div>
      </div>
  );
};

export default ImageDiv;