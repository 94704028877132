import React from "react";

import HeaderBlock from "../../components/headerblock";
import TextBanner from "../../components/textbanner";
import ImageDiv from "../../components/imagediv";
import InfoTextDiv from "../../components/textdiv";
import LinkButton from "../../components/linkbutton";

const ProductsPage = () => {

  return (
    <>
    <div className="content">
    <HeaderBlock
        subheader="Products"
        backgroundImage="images/background/tubing_1.jpg"
        backgroundColour="#0f2b4bd4"
        headerColour="#b4d8f0"
        subHeaderColour="#b4d8f0"
        fontColour="#e1eaf0"
        textPos="right"
        html={
        <div>
          <div className="headerBlockMainHeading" >
            <p>These fully disposable single-use components and higher-level assembly flow paths are designed to overcome common industry problems associated with stainless facilities allowing a convenient, quick, and cost-effective method for the rapid changeover of manufacturing operations.</p>
            <p>With manufacturing taking place under strictly controlled conditions in Ireland, all our components and systems can be supplied fully sterilised or in a sterilisation-ready condition.</p>
          </div>
        </div>} />
    </div>
    <TextBanner
      fontColour="#0a4486"
      backgroundColour="#e1eaf0"
      header="Quality assurance"
      text="At MonoBiologics, we maintain an extensive Quality Management System to monitor and control every aspect of our manufacturing process."
      />
    <ImageDiv
        primaryColor="#b4d8f0"
        secondaryColor="#0a4486"
        header="Assembled tubing flow-paths"
        id="tubingflowpaths"
        imagePos="right"
        html={
          <>
            <p>One of the fundamentals in the single-use biologics industry is tubing flow paths. This element of the process has direct product contact from both a materials interaction and material flow perspective. </p>
            <p>At MonoBiologics, we manufacture and assemble tubing flow paths from both Liquid Silicon Rubber (LSR) and Thermopolymer Elastomers (TPE). While we assemble and test rudimentary flow paths using connectors and fittings, we also use overmolding technologies.</p>
          </>
        }
        extraHTML={
          <p>The use of overmolding technologies improves product flow and eliminates fluid turbulence at intersection points. All our processes and materials go through validation and testing for mechanical, chemical, and structural durability. Additionally, all materials are fully compliant with the extractables and leachable requirements of biologics processing.</p>
        }
        image="images/tubing_4.jpg" />
    <ImageDiv
        primaryColor="#e1eaf0"
        secondaryColor="#0a4486"
        header="Connectors"
        imagePos="right"
        html={
          <>
            <p>All fluid product flow paths at some point need connectors to allow the assembly to integrate into the system. We work with all the primary connections and technology, whether that is integrated molded TC connectors, or fitted aseptic connectors.</p>
            <p>Often customers have their own design or application specific connectors. In this instance, we work with customers to determine the appropriate solution.</p>
          </>
        }
        image="images/tubing_1.jpg" />

    <ImageDiv
        primaryColor="#b4d8f0"
        secondaryColor="#0a4486"
        header="Process control elements"
        id="processcontrol"
        imagePos="right"
        html={
          <>
            <p>A key consideration within a product contact flow path is the elements required to monitor process performance. Depending on the process specific requirements this can include:</p>
            <ul>
              <li>Flow sensors</li>
              <li>Pressure sensors</li>
              <li>Temperature sensors</li>
              <li>PH Sensors</li>
              <li>Conductivity sensors</li>
              <li>Turbidity sensors</li>
            </ul>
          </>
        }
        extraHTML={
          <>
          <p>Naturally, these elements need to be assembled in the flow path and subsequently calibrated before deployment, and these are all parts of the product we manufacture here in Ireland.</p>
          <p>The advantage of including these sensing and control elements in the single-use flow path is that there is a singular installation without the requirement to break connections, which is laborious, potentially hazardous, and also may cause compromise the integrity of the internal sterile chamber.</p>
          </>
          }
        image="images/sensors.jpg" />
    <ImageDiv
        primaryColor="#e1eaf0"
        secondaryColor="#0a4486"
        header="Process Management Elements"
        id="processmanagement"
        imagePos="right"
        html={
          <>
            <p>We have extensive capabilities in all solutions for your process management, including:</p>
            <ul>
              <li>Valving and process control technologies</li>
              <li>Sensoring and closed loop feedback solutions, the usual suspects like flow, pressure, conductivity, pH, resistivity, turbidity, etc.</li>
              <li>Closed loop controls in all upstream processes</li>
            </ul>
            <p>Our applications in upstream processes include:</p>
              <ul>
              <li>Cell isolation</li>
              <li>Cell Cultivation</li>
              <li>Banking and final expansion</li>
              <li>Cell harvesting and cultivation</li>
            </ul>
          </>
        }
        extraHTML={
          <>
          <InfoTextDiv
              htmlLeft={
              <>
                <p>While our capabilities in downstream processes include:</p>
                <li>Chromatography</li>
                <li>Filtration of large and small molecules, Tangential Flow Filtration (TFF), and associate filtration methods</li>
                <li>Buffer exchange</li>
                <li>Diafiltration</li>
                <li>Bioreactor sampling</li>
                <li>Deeper concentration of the product sampling and control capabilities to support large reactor volumes and small batch sizes</li>
              </>
              } 
              htmlRight={
                <>
                <p>More importantly, our product flow path manufacturing processes ensure that product flow paths are extremely controlled and free of turbulence. <br></br>While this is of course vitally important in large batch manufacturing, it becomes vital in cell and gene therapies- where batch integrity is critical in smaller volumes.</p>
                <p>We can demonstrate how our technologies ensure minimal necrosis and product losses during your processes.</p>
                </>
              }
            />
            <p>The key advantages of our technologies are:</p>
                <ul>
                  <li>Extremely accurate, predictable, and repeatable fluid controls, with timely feedback.</li>
                  <li>Controlled processes that ensure minimal fluid turbulence and therefore are easily readily validated</li>
                  <li>Instantly interchangeable product contact flow paths to allow either product to product, or batch to batch, traceability. </li>
                  <li>Demonstrable quality standards, to BPOG’s Extractables & Leachable requirements and all the associated industry standards such as ISO, FDA, IMB, etc.</li>
                </ul>
          </>
        }
        image="images/tubing_2.jpg" />
    <ImageDiv
        primaryColor="#b4d8f0"
        secondaryColor="#0a4486"
        header="Tubing Management"
        id="tubingmanagement"
        imagePos="right"
        html={
          <>
            <p>As single-use processes become more common in our sector, tubing management is occasionally implemented haphazardly. This may be adequate, but we can improve it with a few simple changes. We can help you with process flow tubing management and regularly work with clients to ensure that their processes are as controlled as possible.</p>
            <p>While you focus on product development and manufacturing, we will collaborate with your team to ensure that the methods you use to manufacture valuable products do not result in losses or inefficiencies.</p>

          </>
        }
        image="images/production_1.jpg" />

    <ImageDiv
        primaryColor="#e1eaf0"
        secondaryColor="#0a4486"
        header="Integrated and Standalone Elements"
        id="integratedstandaloneelements"
        imagePos="right"
        html={
          <>
            <p>We partner with our customers to deliver solutions for their processes, and completely understand that every process can be slightly different.</p>
            <p>As a result, we have built expertise in areas that can support you from a product supply, and project management perspective. This means that we collaborate with your team to implement tailored solutions to your needs. </p>
          </>
        }
        extraHTML={
          <>
          <p>However, not everything should be bespoke, thus we provide conventional process control elements and consumables that are available off the shelf and on short lead times such as:</p>
            <ul>
              <li>Single-use product flow path consumables, from simple valve liners to complex assemblies integrating multiple process components (pump heads, sensors, closed loop controls, etc.)</li>
              <li>Process control valving, diaphragm controlled and non-diaphragm</li>
              <li>Intelligent sensing and closed loop feedback</li>
              <li>Intelligent pumping applications</li>
              <li>Ability to include Artificial intelligence into the closed loop controls we can integrate</li>
            </ul>
            <p>However, the most crucial application in which we can collaborate with you on is in the field of cell and gene therapy.</p>
            <p>Our ability to combine process control elements and mold flow paths are key to the successful production of customised and bespoke medications. Recent advances in medicine have demonstrated that, while the technology exists to improve people's lives, the expense is prohibitively high. We intend to change that.</p>
            <LinkButton
            link="/quality"
            text="Quality Assurance"
            />
          </>
        } 
        image="images/mini_bioreactor.jpg" />
    </>

    
  );
};

export default ProductsPage;