import React from "react";

import HeaderBlock from "../../components/headerblock";
import TextBanner from "../../components/textbanner";
import DivBanner from "../../components/divbanner";
import InfoTextDiv from "../../components/textdiv";
import ImageDiv from "../../components/imagediv";

const QualityPage = () => {

  return (
    <>
      <div className="content">
      <HeaderBlock
        subheader="Quality & Compliance"
        backgroundImage="images/background/guage_1.png"
        backgroundColour="#0f2b4bd4"
        headerColour="#b4d8f0"
        subHeaderColour="#b4d8f0"
        fontColour="#e1eaf0"
        textPos="right"
        html={<div>
          <p className="headerBlockMainHeading">
            At MonoBiologics, we maintain an extensive Quality Management System to monitor and control every aspect of our manufacturing process. 
          </p>
          <p>
            Recognising that compliance with Good Manufacturing Practices (GMP) is a mandatory aspect of BioPharma manufacturing, we work to the following regulatory standards:
          </p>
          <ul>
            <li><a href="https://www.iso.org/iso-9001-quality-management.html" target="_blank" rel="noreferrer">ISO 9001</a> - Quality Management Systems</li>
            <li><a href="https://www.iso.org/standard/59752.html" target="_blank" rel="noreferrer">ISO 13485</a> - Medical devices manufacturing QMS</li>
            <li><a href="https://www.iso.org/standard/66451.html" target="_blank" rel="noreferrer">ISO 11737</a> - Sterilisation of Healthcare products, Microbiological methods</li>
            <li><a href="https://www.iso.org/standard/53394.html" target="_blank" rel="noreferrer">ISO 14644</a> - Cleanrooms and controlled environments </li>
            <li><a href="https://www.biophorum.com/biophorum-council/" target="_blank" rel="noreferrer">BPOG</a> - General industry advisory council, particularly relevant in Extractables and Leachable studies</li>
            <li><a href="https://www.iso.org/standard/66451.html" target="_blank" rel="noreferrer">ISO 11737</a> - Bioburden alert and action levels</li>
            <li><a href="https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfcfr/cfrsearch.cfm?fr=10.115" target="_blank" rel="noreferrer">US Food and Drug Administration FDA 21CFR 10.115 (b)</a></li>
          </ul>
          <p>
            Would you like to find out more about our quality management system? Come and visit our manufacturing site and see for yourself.
          </p>
        </div>} />
        {/* <ImageDiv 
          header={"About PM Group"} 
          html={<>
            <p>PM Group is the originator of the SDD™ initiative.</p>
            <p>
              PM Group is an employee owned, international project delivery company, with operations across Europe,
              the USA and Asia. The Company has a 48 year track record in project management, process design,
              facility design and construction management for leading multinational companies. It is a world leader in
              the Pharma, Food, Mission Critical, Med-Tech and Advanced Manufacturing sectors.</p>
            </>} 
          imagePos={"right"} 
          image={"images/logos/pm_group_logo.png"} 
          primaryColor={"#b4d8f0"} 
          secondaryColor={"#0a4486"}
          radiusPercent={0} 
          boxShadow={false}/>
        <TextBanner 
        text={<a href="https://www.aseptic-group.com/en/what-is-sdd/" >"What is SDD (Standard Disposable Design)"</a>}
        backgroundColour="#0f2b4bd4"
        />
        <InfoTextDiv
          primaryColor="#e1eaf0"
          htmlLeft={
            <>
              <p>Globally shared across industry</p>
              <p>The vision for SDD™ is a world where most major biopharmaceutical companies store their medias and
              buffers in bags of the same design. Our goal is to facilitate the standardisation of auxiliary functions
              disposable design between biopharmaceutical companies.</p>
              <p>Read more on the <a href="https://bioprocessintl.com/manufacturing/single-use/benefits-of-single-use-standardization-adopting-a-standard-design-approach/" target="_blank" rel="noreferrer">Benefits of Single-Use Standardization: Adopting a Standard Design Approach</a></p>
            </>
          }
          htmlRight={
            <>
              <p>Increased efficiency</p>
              <p>SDD™ is a unique service solution that speeds the design of drug manufacturing processes through the
              use of standardised components. This global innovation offers huge benefits to the pharmaceutical
              industry by helping drugs come to market faster, reducing the cost of manufacture and producers&#39; stock
              levels of key components.</p>
              <p>This facilitates global availability of standard components through networks of suppliers, reducing
              leadtimes and ensuring security of supply.</p>
            </>
          }
        />
        <ImageDiv 
          header={"SDD Compatibility"} 
          html={<>
            <p>This is the next step in the journey towards increased industry standardisation. selecting a product
            marked with the SDD Compatible™ label, you are selecting something which has been checked to
            ensure it will fit the compatible part or assembly from the SDD™ library.</p>
            </>} 
          imagePos={"right"} 
          image="images/logos/sdd_logo.jpg" 
          primaryColor={"#b4d8f0"} 
          secondaryColor={"#0a4486"}
          radiusPercent={0} 
          extraHTML={
            <>
            <span style={{fontWeight:400}}>Peace of mind</span>
            <p>SDD Compatible™ Is the perfect partner for SDD™.While you may choose to go with one vendor or many for SDD™, SDD Compatible™ equipment is designed to fit and checked for compatibility.</p>
            <p>Significant benefits</p>
            <ul>
              <li>Simplified streamline design</li>
              <li>Speed of procurement</li>
              <li>Assured compatibility</li>
              <li>Dimensional Standardisation</li>
              <li>Reduced leadtime</li>
              <li>Improved security of supply</li>
              <li>Interchangeability between multiple suppliers</li>
              <li>Interconnectivity of your assemblies between different suppliers</li>
              <li>Get ‘Like for like’ comparisons</li>
              <li>Speed up your plant design</li>
            </ul>
            </>
          }/>

      <DivBanner
      backgroundColour="#e1eaf0"
      fontColour="#0a4486"
      html={
        <>
        <p>Compatibility with SDD Compatible™</p>

        <p><a href="https://www.pmgroup-global.com/what-we-do/sectors/pharmaceuticals/standard-disposables-design/" target="_bank" rel="noreferrer"> Standard Disposables Design | Pharmaceuticals | PM Group (pmgroup-global.com)</a></p>
        <p>Is part of the SDD Initiative for the standardisation supply of single use consumables and process
        controls for the Biologics manufacturing industry</p>
        </>
      }
       /> */}
      </div>
    </>
  );
};

export default QualityPage;