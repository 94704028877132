import React from 'react';
import './headerblock.css';
import { Link } from "react-router-dom";

const HeaderBlock = ({ 
  header,
  headerColour,
  subheader, 
  subHeaderColour,
  fontColour,
  image, 
  textPos, 
  html, 
  backgroundImage,
  backgroundColour

}) => {

  return (
  
    textPos=="right" ?
    <>
    <div className='headerBlockBackgroundImage' style={{backgroundImage: `url(${backgroundImage})`}}>
      <div className='headerBlockBackgroundColour' style={{backgroundColor: backgroundColour}}>
        <div className="headerBlock">
          <div className='textBlock'>
            <div className="headerText">
              <span className='header' style={{color:headerColour}}>{header}</span><br></br>
              <span className='subHeader' style={{color:subHeaderColour}}>{subheader}</span>  
            </div>
            <div className='text'style={{color:fontColour}}>
                {html}
              </div>
          </div>
          {image === undefined?
             <></>:
             <>
             <div className="headerImage">
              <img src={image}></img>
            </div>
            </>
          }
          
        </div>
      </div>
      </div>
    </>
    :
    <>
    <div className='headerBlockBackgroundImage' style={{backgroundImage: `url(${backgroundImage})`}}>
    <div className='headerBlockBackgroundColour' style={{backgroundColor: backgroundColour}}>
      <div className="headerBlock">
        {image === undefined?
             <></>:
             <>
             <div className="headerImage">
              <img src={image}></img>
            </div>
            </>
        }
        <div className='textBlock'>
          <div className="headerText">
            <span className='header'>{header}</span><br></br>
            <span className='subHeader'>{subheader}</span>  
          </div>
          <div className='text'>
              {html}
            </div>
        </div>
      </div>
    </div>
    </div>
    </>
  );
};

export default HeaderBlock;