import React from "react";
import ImageDiv from "../../components/imagediv";
import HeaderBlock from "../../components/headerblock";
import TextBanner from "../../components/textbanner";
import InfoTextDiv from "../../components/textdiv";
import LinkButton from "../../components/linkbutton";

const AboutPage = () => {

  return (
    <>
    <div className="content">
      <HeaderBlock header="About"
        subheader="Your Introduction to MonoBiologics."
        backgroundImage="images/background/clean_room.png"
        backgroundColour="#0f2b4bd4"
        headerColour="#b4d8f0"
        subHeaderColour="#b4d8f0"
        fontColour="#e1eaf0"
        textPos="right"
        html={<div>
          <p className="headerBlockMainHeading">
            At MonoBiologics, we manufacture and supply single-use biologics solutions. Within our team, we have over 25 years’ experience manufacturing single-use solutions for the biopharma and medical device industries. This experience gives us the ability to be versatile with product design and development to drive single-use innovations.
          </p>
          <p>
            Using our expertise in biopharma and medical device manufacturing, we provide single-use technologies that help customers manufacture next generation medicines to improve healthcare.
          </p>
          <p>
            In addition to our standard single-use solutions we also partner with customers to develop single-use technologies that are not currently in their space.
          </p>
          <p>
            Working with international biopharma companies, we very much see MonoBiologics as being part of a customer’s supply chain. The manufacture of healthcare products is vital, and we play an important part in the provision of those products to the global market.
          </p>
        </div>} />
      
      <ImageDiv
        primaryColor="#e1eaf0"
        secondaryColor="#0a4486"
        header="Vision"
        imagePos="right"
        html={
          <>
            <p>
            Our vision is simple, we develop single-use solutions for the biopharma industry to ensure that essential medicines are available to the people that need them most. We are innovators and know from first-hand experience that the industry we support is growing due to innovative thinking. There is going to be a shift in our industry, and we intend to lead it.
            </p>
          </>
        }
        image="images/logos/logo_icon.png" 
        radiusPercent={0} 
        boxShadow={false}
        />
      <TextBanner
      image="images/img4.png"
      
      backgroundColour="#0f2b4bd4"
      header="Our Mission"
      text="Driving everyone’s access to life-changing medicine."
      />
    <ImageDiv
      primaryColor="#b4d8f0"
      imagePos="left"
      secondaryColor="#0a4486"
      header="Why work with MonoBiologics?"
      html={
        <>
        <p>
          Having been at the coal face during the expansion of biologics manufacturing over the past years, we want to overcome the supply the supply chain and technological challenges that this industry faces.
        </p>
        <p>
        <div className="imageDivSubHeader">Overcome supply chain issues</div>
        The BioPharma industry is experiencing extensive lead times, and there is no sign of this improving. To overcome this we manufacture, design and test all our products at our manufacturing sites in Ireland. 
        </p>
        <p>
          Our industry experience combined with our in-house manufacturing capabilities, gives us the ability to disrupt the supply chain and minimise your lead times. Using existing technology, we work closely with customers to ensure continuity of what has become a fragile supply chain. For critical items we can hold stock in our warehouse on a call off basis for when you need it most. 
        </p>
        <LinkButton
        text="Supply"
        link="/products#integratedstandaloneelements"/>
        </>
      }
      image="images/production_3.jpg" />

    <ImageDiv
        primaryColor="#e1eaf0"
        imagePos="right"
        secondaryColor="#0a4486"
        header="Worry less and Manufacture More"
        html={
          <>
          <p>With our single-use products you can:</p>
          <ul>
            <li>Improve patient safety</li>
            <li>Reduce switchover requirements through a more efficient use of equipment</li>
            <li>Increase assurance of product compliance</li>
            <li>Minimise changeover times</li>
            <li>Reduce validation requirements</li>
          </ul>
          <LinkButton
          text="Assembled tubing flow-paths"
          link="/products#tubingflowpaths"/>
          </>
        }
        image="images/production_6.jpg" />

    <ImageDiv
        primaryColor="#b4d8f0"
        imagePos="left"
        secondaryColor="#0a4486"
        header="Drive innovation"
        html={
          <>
          <p>As the single-use industry moves more towards next-generation solutions for cell and gene therapies we are responsive to changing technological demands. Working with our customers we:</p>
          <ul>
            <li>Provide technologies that maintain smaller volume manufacturing consistency </li>
            <li>Ensure that our technologies are viable for small volume manufacturing and processing</li>
            <li>Assist with adopting single-use processing technologies to reduce process changeover times from batch to batch</li>
          </ul>
          </>
        }
        extraHTML={
        <>
        <p>One of the major challenges currently facing this technology is, quite simply, cost. This is where our technologies can assist.</p>
        <p>Another consideration is the requirement to absolutely get the processing of the cells right on the first go. With our technologies, you can have confidence that your upstream and downstream proccing will be completely robust.</p>
        <p>In addition to cell and gene technologies, we also support large volume batch requirements. Oursingle-usee consumables and process control technologies are accurate, efficient, validated, and cost-effective</p>
        <LinkButton
        link="/services#transitionsdd"
        text="Transition to Single Use"
        />
        </>
        }
        image="images/production_2.jpg" />
      
      <InfoTextDiv
        primaryColor="#e1eaf0"
        htmlLeft={
          <> 
          <p className="infoDivSubHeader">Embed sensors</p> 
          <p>
            While production must be fast and effective, it needs to meet quality standards. To ensure consistent scale-up of biopharma manufacturing operations we offer reliable sensors for process control at each stage of the process.
          </p>
          <p>
            The integration of single-use sensors can support process analysis by measuring conductivity, PH, flow, and pressure. We can help you to get it right from the start and maximise productivity.
          </p>
          <LinkButton
          link="/products#processcontrol"
          text="Process Control Elements"
          />
          </>
        }
        htmlRight={
          <>
          <p className="infoDivSubHeader">Support for additional process requirements</p>
          <p>
            Often customers have requirements beyond standard single-use flow path elements. 
          </p>
          <p>
            In addition to standard single-use flow paths, we also work with customers to develop bespoke flow paths. In many cases, these are unique to either our customer’s specific process requirements or their product manufacturing process. 
          </p>
          <LinkButton
          link="/services#designconsultancy"
          text="Design Consultancy"
          />
          </>
        }
        />
      <ImageDiv
        primaryColor="#b4d8f0"
        imagePos="right"
        secondaryColor="#0a4486"
        header="Working to industry standards"
        html={
          <>
          <p>At MonoBiologics, we maintain an extensive Quality Management System to monitor and control every aspect of our manufacturing process.</p>
          <LinkButton
          link="/quality"
          text="Quality Assurance"
          />
          </>
        }
        image="images/standards.jpg" />
        
    </div>
    <InfoTextDiv
        primaryColor="#e1eaf0"
        header="Meet our Team"
        htmlLeft={
          <> 
          <p className="infoDivSubHeader">Dr. Jonathan Downey</p>
          <ul>
            <li>Ph.D., Process Engineering, MSc. BioEngineering, B.Sc. Manufacturing Engineering</li>
            <li>25 Years in Medical Devices Manufacturing and Research & Development</li>
          </ul>
          </>
        }
        htmlRight={
          <>
          {/* <p className="infoDivSubHeader">Seamus Kilgannon</p>
          <ul>
            <li>Graduate Leadership at Stanford University, B.Eng. in Production Engineering at UCG</li>
            <li>Vice President of Manufacturing for Waters Technologies and Founder of Schivo</li>
            <li>Subsequently Founder of WorkLAB and Investor in a number of enterprises in related spaces</li>
          </ul> */}
          </>
        }
        />
  </>
  );
};

export default AboutPage;