import './App.css';

import Navbar from './components/navbar/'
import Footer from './components/footer/'
import { BrowserRouter, Route, Routes} from "react-router-dom"

import HomePage from './pages/home';
import ContactPage from './pages/contact';

import ScrollTop from './components/scrollTop';
import ServicesPage from './pages/services';
import AboutPage from './pages/about';
import QualityPage from './pages/quality';
import CareersPage from './pages/careers';
import ProductsPage from './pages/products';

function App() {
  return (
    <BrowserRouter>
      <Navbar/>
      <ScrollTop />
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/contact" element={<ContactPage/>} />
        <Route path="/services" element={<ServicesPage/>} />
        <Route path="/about" element={<AboutPage/>} />
        <Route path="/quality" element={<QualityPage/>} />
        <Route path="/products" element={<ProductsPage/>} />
        <Route path="/careers" element={<CareersPage/>} />
        <Route path="*" element={<HomePage/>}/>
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;