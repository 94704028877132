import React from "react";

import "./swiperCard.css"
const SwiperCard = ({image,header,text}) => {

  return (
    <>
    <div className="swiperCard" style={{backgroundImage: `url(${image})`}}>
      <div className="swiperCardTextBlock">
          <div className="swiperCardHeader">{header}</div>
          <div className="swiperCardText">{text}</div>
      </div>
    </div>
    </>
  );
};

export default SwiperCard;