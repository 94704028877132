import React from 'react';
import styles from './textdiv.css';

const InfoTextDiv = ({header, htmlLeft, htmlRight, primaryColor}) => {
    
  return (
    <>
     <div className="infoDivBackground" style={{ backgroundColor: primaryColor}}>
        <div className='infoDivContainer'>
          {header===undefined?<></>:<div className='infoDivHeader'>{header}</div>}
            <div className='infoDivLeft'>
                {htmlLeft}
            </div>
            <div className='infoDivRight'>
                {htmlRight}
            </div>
        </div>
      </div>
    </>
  );
};

export default InfoTextDiv;