import React from "react";
import ImageDiv from "../../components/imagediv";
import InfoTextDiv from "../../components/textdiv";
import SwiperHeader from "../../components/swiper/swiperHeader";
import LinkButton from "../../components/linkbutton";

const HomePage = () => {

  return (
    <>
      <div className="content">
        <SwiperHeader />
        <ImageDiv header={"Are you in the Biotechnology processing industry and experiencing a shortfall in the industry’s supply chain?"} html={<>
          <p>
            For biologics manufacturers who are looking to accelerate the time to market, MonoBIOlogics supply both simple and expanded flow path assemblies for application in upstream and downstream biologics manufacturing.
          </p>
          <p>See how you can simplify assembly, remove the need for cleaning and accelerate batch changeovers</p>

        </>}

          extraHTML={
            <>
              <h3 style={{fontWeight:400}}>Products</h3>
              <p>
                From our manufacturing site in Ireland, we create complete single use flow paths in compliance with GMP and associated industry standards. As part of the process we design, manufacture and support you in integrating these single-use components
              </p>

              <ul>
                <li>Assembled tubing flow-paths</li>
                <li>Connectors</li>
                <li>Process control elements</li>
                <li>Process management elements</li>
                <li>Tubing management</li>
                <li>Integrated and standalone filter elements</li>
              </ul>
              <LinkButton
              link="/products"
              text="products"/>
              </>
          }

          image={"images/production_3.jpg"} primaryColor={"#e1eaf0"} secondaryColor={"#0a4486"} />


        <ImageDiv header={"Regulated Manufacturing"} html={<>

          <p>With manufacturing taking place under strictly controlled conditions in Ireland, all our components and systems can be supplied fully sterilised or in a sterilisation ready condition</p>
          <h3 style={{fontWeight:400}}>As the single use transition happens, we can also support:</h3>
          <ul>
            <li>Valving and all the stainless-steel elements that still need to be part of your process</li>
            <li>Deploying sensors for both existing single use and hybrid processes</li>
          </ul>
          <LinkButton
              link="/quality"
              text="quality"/>
        </>} imagePos={"left"} image={"images/production_2.jpg"} primaryColor={"#b4d8f0"} secondaryColor={"#0a4486"} />


        <InfoTextDiv
          primaryColor="#e1eaf0"
          htmlLeft={
            <>
              <p style={{ fontSize: '40px' }}>Do you have specific requirements?</p>
            </>
          }
          htmlRight={
            <>
              <p>
                Together with our customers we are developing the required capabilities to support the implementation of smaller batch Cell and Gene therapy solutions and understand the pain points associated with these processes.
              </p>

              <p>
                Our design team can work with you to develop custom single-use configurations and outline how we have supported and partnered with customers on more standardised solutions.
              </p>
              <LinkButton
              link="/services"
              text="services"/>
            </>
          }
        />


      </div>
    </>

  );
};

export default HomePage;